import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styles from './article-featured.module.css'
import styled from 'styled-components';
const ArticleImg = styled(Img)`
  margin-top: 0px;
`
const ArticleTitle = styled('h2')`
margin-top: -20px;
margin-bottom: 20px;
z-index: 20;
left: -20px;
position: relative;
`;
const ArticleBody = styled('p')`
  h3 {
  font-size: 1.4em;
  }
  h3,h2,h1 {
    margin: 0px;
  }
`;
export default ({ article, className }) => (
  <div className={styles.preview}>
    <div className={'col-3'}>
        <ArticleImg  alt="" fluid={article.heroImage.fluid} />

    </div>
    <div className={'col-9'}>

      <h3 className={styles.previewTitle}>
        <Link style={{paddingLeft:0}} className={styles.articleLink + ' col-3'} to={`/blog/${article.slug}`}>{article.title}</Link>
      </h3>
      <small>{article.publishDate}</small>
      <ArticleBody
          dangerouslySetInnerHTML={{
            __html: article.description.childMarkdownRemark.html,
          }}
      />

      { article.tags && article.tags.length > 0 &&
      article.tags.map(tag => (
          <p className={styles.tag} key={tag}>
            {tag}
          </p>
      ))}
      <Link style={{paddingLeft:0, float:'right'}} className={styles.articleLink + ' col-3'} to={`/blog/${article.slug}`}>Read Blog</Link>
    </div>

  </div>
)

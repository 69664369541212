import React, {Fragment} from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import styles from './blog.module.css'
import Layout from "../components/layout"
import LayoutSimple from "../components/layout-simple"
import ArticlePreview from '../components/article-preview'
import ArticleFeatured from '../components/article-featured'
import Img from "gatsby-image";
import heroStyles from "../components/hero.module.css";
import styled from 'styled-components';
import Heading from "@kiwicom/orbit-components/lib/Heading";
import tokens from '../tokens';
import blob from '../blob/blob-shape_green_a.svg';


const ArticleList = styled('ul')`
    li h2 { 
      padding-left: 20px;
      padding-right: 20px;
      color: white !important;
      
    }
    li h3 {
      margin: 0px;
    }
    li:nth-child(n) {
        
       h2 {
        transform: rotate(-2deg); 
        background: ${tokens.brand('molly')} !important;
       }
       
    }
    small {
      
    }
    li:nth-child(2n) {
       
        h2 {
          transform: rotate(2deg); 
            background: ${tokens.brand('nel')} !important; 
        }
        
    }
    li:nth-child(3n)  {
      h2 { 
            background: ${tokens.brand('bonnie')} !important; 
        }
      
    }
    li:nth-child(4n) {
        h2 {
        transform: rotate(-2deg) translateY(0px);; 
            background: ${tokens.brand('dex')} !important; 
        }
        
         
    } 
`;

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const posts = get(this, 'props.data.allContentfulBlogPost.edges');
    const pinnedPosts = get(this, 'props.data.pinnedPosts.edges');
    const blogHero = get(this, 'props.data.blogHero.childImageSharp');

    return (
      <LayoutSimple location={this.props.location} >
        <div >
          <Helmet title={siteTitle} />
          <div className={'row'} >
            <img className={'blob-top-right'} src={blob} />
            <div className={heroStyles.hero + ' col-7'}>
              <Img className={heroStyles.heroImage} {...blogHero} />
            </div>
            <div className={'col-5 col'}>
              <h1>Our Doodle Blog</h1>
              <p>Welcome to our Kingscote Doodles blog. We write about all of doggy adventures, our regular owner meetups and during expectant litters we keep you updated with how our Doodles are getting on.</p>
              { pinnedPosts.length > 0 &&
                <Fragment>
                  <p>This is the last blog we posted:</p>
                  {pinnedPosts.map(({node}, index) => {
                    return (
                    <ArticleFeatured key={index} className={'inline-preview'} article={node} />
                    )
                  })}
                </Fragment>
              }

            </div>

          </div>

          <div className="wrapper">
            <h2 className="section-headline">Our Recent Doodle Adventures</h2>
            <ArticleList className="article-list">
              {posts.map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <ArticlePreview article={node} />
                  </li>
                )
              })}
            </ArticleList>

          </div>
        </div>
      </LayoutSimple>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    blogHero: file( relativePath: {eq: "blog-hero.jpg"} ) {
      childImageSharp {
        fluid(maxWidth: 1920, cropFocus: CENTER, maxHeight: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pinnedPosts : allContentfulBlogPost(filter: {node_locale: {eq: "en-US"}, pinnedPost: {eq: true}}) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 650, maxHeight: 596, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulBlogPost(filter: {node_locale: {eq: "en-US"}}, sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 650, maxHeight: 596) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
